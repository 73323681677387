import React from 'react';
import styled from 'styled-components';
import config from '../../services/config';
import Link from 'next/link';
import { Icon, Label } from 'semantic-ui-react';
import layout from '../../services/layout';
import SubscribeToNewsletter from './SubscribeToNewsletter';

const FooterContainer = styled.footer`
  background-color: #1b1b1b;
`;
const FooterNav = styled.div`
  display: inline-block;
  width: 20%;
  padding: 0 20px;
  vertical-align: top;
  &:first-child {
    margin-left: 5%;
    width: 25%;
  }

  p {
    color: #ccc;
  }

  small {
    display: block;
    color: #ccc;
    margin-top: 40px;
  }

  @media only screen and (max-width: ${layout.global.mediumMax}) {
    width: 50% !important;
    margin-left: 0 !important;
    margin-top: 20px;
  }

  @media only screen and (max-width: ${layout.global.mobileMax}) {
    width: 100% !important;
  }
`;

const FooterNavCollection = styled.div`
  padding: 60px 20px 50px;
`;

const FooterLower = styled.div`
  padding: 20px;
  color: #ddd;
  background-color: #2b2b2b;
  font-size: 90%;

  @media only screen and (max-width: ${layout.global.mediumMax}) {
    padding-bottom: 80px;
  }
`;

const FooterLowerNav = styled.div`
  display: inline-block;
  width: 40%;
  padding: 0 20px;
  vertical-align: top;
  &:first-child {
    margin-left: 5%;
    width: 50%;
  }
  &:last-child {
    text-align: right;
  }

  p {
    color: #ccc;
  }

  @media only screen and (max-width: ${layout.global.smallMax}) {
    display: block;
    text-align: center !important;
    width: 100% !important;
    margin-left: 0 !important;
    &:last-child {
      margin-top: 20px;
    }
  }
`;

const FooterLink = styled.a`
  color: #eee !important;
  text-decoration: underline !important;
  &:hover {
    color: #fff !important;
  }
`;

const FooterSocialLink = styled.a`
  color: #eee !important;
  padding: 0 10px;
  display: inline-block !important;
`;

const Clr = styled.div`
  clear: both;
`;

const NavTitle = styled.h3`
  color: white;
  margin-bottom: 4px;
`;
const NavList = styled.ul`
  padding: 0;
  margin: 0;
`;
const NavItem = styled.li`
  list-style: none;
  padding: 3px 0;
  > a {
    color: #ddd;
  }
  > a:hover {
    color: #fff;
  }
`;

const Highlight = styled.span`
  color: #ed1f24;
`;

const Line = styled.div`
  height: ${(props) => props.$thickness + 1 || 4}px;
  border-bottom: 2px solid black;
  background-color: ${(props) => props.$color || '#ed1f24'};
`;

const NewLabel = styled(Label)`
  background: #1b1b1b !important;
  color: white !important;
  border-color: white !important;
  margin-left: 15px !important;
`;

function Footer() {
  const analyticSourceTag = '?source=FOOTER';

  return (
    <FooterContainer>
      <SubscribeToNewsletter />
      <Line $thickness={6} />
      <Line $thickness={4} />
      <Line $color="#ff851b" $thickness={6} />
      <FooterNavCollection>
        <FooterNav>
          <NavTitle>Classifieds</NavTitle>
          <NavList>
            <NavItem>
              <Link
                href={
                  '/search/car-bike-kart/circuit-racing' + analyticSourceTag
                }
              >
                Circuit Racing
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={'/search/car-bike-kart/speedway-oval' + analyticSourceTag}
              >
                Speedway &amp; Oval
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={
                  '/search/car-bike-kart/rally-off-road-4wd' + analyticSourceTag
                }
              >
                Rally &amp; Off Road
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={
                  '/search/car-bike-kart/drag-racing-hot-rods' +
                  analyticSourceTag
                }
              >
                Drag Racing &amp; Hot Rods
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={
                  '/search/car-bike-kart/road-drift-performance' +
                  analyticSourceTag
                }
              >
                Road, Drift &amp; Performance Cars
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={
                  '/search/car-bike-kart/classic-prestige' + analyticSourceTag
                }
              >
                Classics &amp; Prestige
              </Link>
            </NavItem>
            <NavItem>
              <Link href={'/search/car-bike-kart/bikes' + analyticSourceTag}>
                Race Bikes
              </Link>
            </NavItem>
            <NavItem>
              <Link href={'/search/trailer-transporter' + analyticSourceTag}>
                Trailers, Transporters &amp; Tow Vehicles
              </Link>
            </NavItem>
            <NavItem>
              <Link
                href={'/search/parts-accessories-other' + analyticSourceTag}
              >
                Parts, Accessories &amp; Other
              </Link>
            </NavItem>
            <NavItem>
              <Link href={'/search' + analyticSourceTag}>View All</Link>
            </NavItem>
          </NavList>
        </FooterNav>

        <FooterNav>
          <NavTitle>Other Links</NavTitle>
          <NavList>
            <NavItem>
              <Link href={config.get('SELL_SITE_URL')}>Place New Ad</Link>
            </NavItem>
            <NavItem>
              <Link href={config.get('SELL_SITE_URL') + 'dashboard'}>
                Manage Ad
              </Link>
            </NavItem>
            <NavItem>
              <Link href={'/about-my105'}>Contact Us</Link>
            </NavItem>
            <NavItem>
              <Link href={'/finance'}>
                Finance
                <NewLabel pointing="left" basic>
                  New
                </NewLabel>
              </Link>
            </NavItem>
            <NavItem>
              <Link href={'/business/advertising'}>Business Advertising</Link>
            </NavItem>
            <NavItem>
              <Link href={'/how-auction-works'}>How Auction Works</Link>
            </NavItem>
            <NavItem>
              <Link href={'/terms'}>Site Terms & Conditions</Link>
            </NavItem>
            <NavItem>
              <Link href={'/auction-terms'}>Auction Terms & Conditions</Link>
            </NavItem>
          </NavList>
        </FooterNav>

        <FooterNav>
          <NavTitle>
            About <Highlight>my105</Highlight>
          </NavTitle>
          <p>
            Since 2002, my105.com has been Australia&apos;s #1 motorsport and
            performance classifieds website.
          </p>
          <p>
            We&apos;re passionate about motorsport and performance in all its
            forms and are constantly looking for new ways to connect drivers,
            fans and the businesses who support them.
          </p>
          <div id="my105-footer-portal" />
        </FooterNav>

        <Clr />
      </FooterNavCollection>

      <FooterLower>
        <FooterLowerNav>
          <p>
            &copy; my105.com 2002-{new Date().getFullYear()} |{' '}
            <FooterLink href={config.get('WEB_URL') + 'terms'}>
              Legal
            </FooterLink>
            ,{' '}
            <FooterLink href={config.get('WEB_URL') + 'terms'}>
              Privacy Policy
            </FooterLink>{' '}
          </p>
        </FooterLowerNav>
        <FooterLowerNav>
          <FooterSocialLink
            href="https://www.facebook.com/my105classifieds/"
            target="_blank"
            aria-label="My105 Facebook"
          >
            <Icon name="facebook f" size="large" />
          </FooterSocialLink>
          <FooterSocialLink
            href="https://www.instagram.com/my105_team/"
            target="_blank"
            aria-label="My105 Instagram"
          >
            <Icon name="instagram" size="large" />
          </FooterSocialLink>
        </FooterLowerNav>
      </FooterLower>
    </FooterContainer>
  );
}

export default Footer;
