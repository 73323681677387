// IMPORTANT!  This file is included on the client side so should not contain any secrets

const config = {
  develop: {
    SEARCH_API_URL:
      'https://ida4zjoi12.execute-api.ap-southeast-2.amazonaws.com/dev',
    SELL_API_URL:
      'https://fawpqf6v4g.execute-api.ap-southeast-2.amazonaws.com/dev',
    COGNITO_USER_POOL_ID: 'ap-southeast-2_JICLJjX3O',
    COGNITO_USER_POOL_CLIENT_ID: '4bmhigfnfqsv1f73af0q7eqs60',
    COGNITO_USER_POOL_DOMAIN: 'auth-dev.my105.com',
    COGNITO_REDIRECT_URL:
      'https://staging-4a5702cd-18d0-4651-b277-74fe3a42e626.my105.com/',
    RECAPTCHA_SITE_KEY: '6Lf42SMaAAAAAKAXngmwGO4AuQF6363b9ZMufP1I',
    WEB_URL: 'https://staging-4a5702cd-18d0-4651-b277-74fe3a42e626.my105.com/',
    WEB_HOST: 'staging-4a5702cd-18d0-4651-b277-74fe3a42e626.my105.com',
    SELL_SITE_URL: 'https://sell.dev.my105.link/',
    GTM_CONTAINER_ID: 'GTM-P2ZBM5N',
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-N0K1SJQV1W',
    DATADOG_CLIENT_TOKEN: 'pubc350db8b76fe2b46562c8ecb4a4ad1a4',
    DATADOG_ENV: 'dev',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCKJdKPyKQ_ZeFgehpnN3XrrQr2-vUCEKY',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_I4puL9swAer3SyhjYOewqYvV',
  },
  main: {
    SEARCH_API_URL:
      'https://b03jc50vrj.execute-api.ap-southeast-2.amazonaws.com/prod/',
    SELL_API_URL:
      'https://ne49kbo4eh.execute-api.ap-southeast-2.amazonaws.com/prod/',
    COGNITO_USER_POOL_ID: 'ap-southeast-2_b1hm1Sgqv',
    COGNITO_USER_POOL_CLIENT_ID: '2s3lcftbqcsa0bur1hnl173r35',
    COGNITO_USER_POOL_DOMAIN: 'auth.my105.com',
    COGNITO_REDIRECT_URL: 'https://www.my105.com/',
    RECAPTCHA_SITE_KEY: '6Lf42SMaAAAAAKAXngmwGO4AuQF6363b9ZMufP1I',
    WEB_URL: 'https://www.my105.com/',
    WEB_HOST: 'www.my105.com',
    SELL_SITE_URL: 'https://sell.my105.com/',
    GTM_CONTAINER_ID: 'GTM-MZKQ6ZM',
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-QKKMZ5S3FT',
    DATADOG_CLIENT_TOKEN: 'pubc350db8b76fe2b46562c8ecb4a4ad1a4',
    DATADOG_ENV: 'prod',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCKJdKPyKQ_ZeFgehpnN3XrrQr2-vUCEKY',
    STRIPE_PUBLISHABLE_KEY: 'pk_live_CVXiacCZ1pu1O5tEod6LKoBW',
  },
  local: {
    SEARCH_API_URL:
      'https://ida4zjoi12.execute-api.ap-southeast-2.amazonaws.com/dev',
    SELL_API_URL:
      'https://fawpqf6v4g.execute-api.ap-southeast-2.amazonaws.com/dev',
    COGNITO_USER_POOL_ID: 'ap-southeast-2_JICLJjX3O',
    COGNITO_USER_POOL_CLIENT_ID: '4bmhigfnfqsv1f73af0q7eqs60',
    COGNITO_USER_POOL_DOMAIN: 'auth-dev.my105.com',
    COGNITO_REDIRECT_URL: 'http://localhost:3000/',
    RECAPTCHA_SITE_KEY: '6Lf42SMaAAAAAKAXngmwGO4AuQF6363b9ZMufP1I',
    WEB_URL: 'http://localhost:3000/',
    WEB_HOST: 'localhost:3000',
    SELL_SITE_URL: 'https://sell.dev.my105.link/',
    GTM_CONTAINER_ID: 'GTM-P2ZBM5N',
    GOOGLE_ANALYTICS_MEASUREMENT_ID: 'G-N0K1SJQV1W',
    DATADOG_CLIENT_TOKEN: 'pubc350db8b76fe2b46562c8ecb4a4ad1a4',
    DATADOG_ENV: 'localhost',
    GOOGLE_MAPS_API_KEY: 'AIzaSyCKJdKPyKQ_ZeFgehpnN3XrrQr2-vUCEKY',
    STRIPE_PUBLISHABLE_KEY: 'pk_test_I4puL9swAer3SyhjYOewqYvV',
  },
};

const hostnameMappings = {
  'www.my105.com': 'main',
  'my105.com': 'main',
};

module.exports = {
  get: (key) => {
    let hostnameEnvironment = null;
    if (typeof window !== 'undefined') {
      hostnameEnvironment = hostnameMappings[window.location.hostname];
    }
    let environment = process.env.AWS_BRANCH || hostnameEnvironment;
    if (!config[environment]) {
      environment = 'local';
    }
    return config[environment][key];
  },
};
